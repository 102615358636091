.faqs_section h2 {
  margin-top: 20rem;
  font-size: 3rem;
  margin-bottom: 5rem;
  text-align: center;
}

.faqs_wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
