.footer_section {
  background-color: var(--secondary-colour);
  padding-top: 10rem;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 5rem;
  padding-bottom: 10rem;
  border-bottom: 0.2rem solid var(--accent-colour);
}

.left_div {
  display: flex;
  flex-direction: column;
  gap: 10rem;
}

.inner_div_wrapper h2,
.inner_div_wrapper h2 {
  font-size: 2.2rem;
}

.inner_div {
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.div_item {
  display: flex;
  align-items: center;
  gap: 3rem;
  color: var(--text-colour);
  text-decoration: none;
  transition: 0.5s;
}

.div_item:hover {
  color: var(--accent-colour);
  transform: scale(0.95);
}

.div_item p {
  font-size: 1.55rem;
  transition: 0.5s;
}

.div_item p:hover {
  color: var(--accent-colour);
}

.footer_icon {
  font-size: 2.4rem;
  color: var(--accent-colour);
}

.signature {
  padding-top: 10rem;
  padding-bottom: 5rem;
  text-align: center;
}

.signature p {
  font-size: 1.4rem;
}

.signature a {
  color: var(--text-colour);
  font-weight: 600;
}

@media screen and (max-width: 680px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 10rem;
  }
}
