.modal_div {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10rem;
  background-color: rgba(0, 0, 0, 0.61);
  backdrop-filter: blur(0.5rem);
  flex-direction: column;
  gap: 1rem;
}

.imageContainer {
  position: relative;
}

.modal_div img {
  width: auto;
  max-width: 100%;
  max-height: 80vh;

  box-shadow: 0rem 0rem 2rem 0rem rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0rem 0rem 2rem 0rem rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0rem 0rem 2rem 0rem rgba(0, 0, 0, 0.1);
}

.caption {
  color: #fff;
  font-size: 1.4rem;
}

.modal_close_btn {
  position: absolute;
  top: 2rem;
  right: 2rem;
  color: #fff;
  font-size: 5rem;
  transition: 0.5s;
  border-radius: 100%;
  cursor: pointer;
  background-color: var(--accent-colour);
}

.modal_close_btn:hover {
  background-color: var(--accent-hover-colour);
  transform: scale(0.95);
}

@media screen and (max-width: 1200px) {
  .modal_div {
    padding: 2rem;
  }
}
