.text_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5rem;
}

.text_div p {
  width: 50%;
  text-align: center;
  font-size: 1.55rem;
}

.btn {
  background-color: var(--accent-colour);
  color: #fff;
  text-decoration: none;
  padding: 2rem 4rem;
  font-size: 1.55rem;
  border-radius: 0.5rem;
  transition: 0.5s;
  cursor: pointer;
  letter-spacing: 0.05rem;
}

.btn:hover {
  background-color: var(--accent-hover-colour);
  transform: scale(0.97);
}

@media screen and (max-width: 680px) {
  .text_div p {
    width: 85%;
  }
}
