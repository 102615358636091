.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.container {
  background-color: var(--secondary-colour);
  display: flex;
  gap: 5rem;
  padding: 3rem;
  border-radius: 1rem;
}

.left {
  display: flex;
  flex-direction: column;
  gap: 5rem;
}

.suburb {
  font-size: 1.8rem;
}

.project_type {
  font-size: 2.7rem !important;
}

.details_body p {
  font-size: 1.55rem;
  margin-top: 1rem;
}

.details_body p::before {
  content: '-';
  font-weight: 900;
  color: var(--accent-colour);
  margin-right: 1rem;
}

.right {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.right img {
  width: 38rem;
  height: 20rem;
  border-radius: 0.5rem;
  object-fit: cover;
}

.img_before_wrapper {
  width: 45rem;
  display: flex;
  justify-content: end;
}

.img_after_wrapper {
  width: 45rem;
  display: flex;
  justify-content: start;
}

.img_before_wrapper div {
  position: relative;
  transition: 0.5s;
  cursor: pointer;
}

.img_before_wrapper div:hover {
  transform: scale(0.95);
}

.img_before_wrapper div::after {
  content: 'Before';
  position: absolute;
  background-color: var(--accent-colour);
  padding: 0.7rem 1.6rem;
  font-size: 1.3rem;
  border-radius: 0.5rem;
  color: #fff;
  bottom: -1.2rem;
  left: 5rem;
}

.img_after_wrapper div {
  position: relative;
  transition: 0.5s;
  cursor: pointer;
}

.img_after_wrapper div:hover {
  transform: scale(0.95);
}

.img_after_wrapper div::after {
  content: 'After';
  position: absolute;
  background-color: var(--accent-colour);
  padding: 0.7rem 2rem;
  font-size: 1.3rem;
  border-radius: 0.5rem;
  color: #fff;
  bottom: -1.2rem;
  right: 5rem;
}

@media screen and (max-width: 680px) {
  .container {
    flex-direction: column;
    gap: 6rem;
  }

  .img_before_wrapper {
    display: block;
    width: 37rem;
  }

  .img_after_wrapper {
    display: block;
    width: 37rem;
  }

  .img_before_wrapper img {
    width: 100%;
  }

  .img_after_wrapper img {
    width: 100%;
  }
}
