.container {
  position: absolute;

  background-color: rgba(248, 249, 250, 0.8);
  -webkit-backdrop-filter: blur(1rem);
  backdrop-filter: blur(1rem);
  box-shadow: 0rem 0rem 2rem 0rem rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0rem 0rem 2rem 0rem rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0rem 0rem 2rem 0rem rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2.5rem;
  border-radius: 0.5rem;
}

.dropdown_item {
  text-decoration: none;
  color: var(--text-colour);
  font-weight: 600;
  font-size: 1.8rem;
  transition: 0.5s;
}

.dropdown_item:hover {
  transform: scale(0.95);
  color: var(--accent-colour);
}

.hide {
  display: none;
}
