.hero_wrapper {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0)
    ),
    url('../../../assets/home/hero.webp');
  height: 65vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero_text_div {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: end;
  padding-bottom: 20rem;
  gap: 9rem;
}

.hero_text_div h1 {
  width: 80rem;
  color: #fff;
  font-weight: 400;
  font-size: 2.4rem;
}

.hero_text_div h1 span {
  font-weight: 600;
  color: var(--accent-colour);
}

@media screen and (max-width: 979px) {
  .hero_text_div {
    padding-bottom: 10rem;
  }

  .hero_text_div h1 {
    width: 60rem;
  }
}

@media screen and (max-width: 768px) {
  .hero_text_div h1 {
    width: 50rem;
  }
}

@media screen and (max-width: 680px) {
  .hero_text_div h1 {
    width: 41rem;
    font-size: 2.2rem;
  }

  .hero_wrapper {
    height: 50vh;
  }
}
