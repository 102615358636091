.header {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.header h2 {
  text-transform: capitalize;
  font-size: 2.2rem;
}

.text_right {
  order: 1;
}

.header div {
  height: 0.2rem;
  min-width: 10rem;
  background-color: red;
  flex-grow: 1;
}
