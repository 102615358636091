.text_div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  width: 80%;
  text-align: center;
  font-size: 1.55rem;
}

.text::after {
  content: attr(data-count);
}

@media screen and (max-width: 680px) {
  .text {
    width: 90%;
  }
}
