.btns_div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: 100%;
  padding-bottom: 2rem;
}

.vertical_line {
  height: 3rem;
  width: 0.2rem;
  background-color: var(--accent-colour);
}

.btns_div button {
  min-height: 5rem;
  background: none;
  outline: none;
  border: none;
  font-weight: 500;
  font-size: 1.4rem;
  flex: 1;
  min-width: 14rem;
  padding: 2rem;
  transition: 0.5s;
  color: #000;
}

.btns_div button:hover {
  cursor: pointer;
  background-color: var(--accent-hover-colour-light);
}

.btn_clicked {
  background-color: var(--accent-colour) !important;
  color: #fff !important;
  font-weight: 900;
}

@media screen and (max-width: 979px) {
  .btns_div {
    overflow-x: scroll;
    scrollbar-color: var(--accent-colour);
  }
}
