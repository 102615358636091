.cards_div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.card {
  width: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--secondary-colour);
  border-radius: 1rem;
  padding: 2.4rem;
  gap: 2rem;
  transition: 0.3s;
  text-decoration: none;
}

.card_header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.photo {
  width: 10rem;
  height: 10rem;
  object-fit: cover;
  border-radius: 100%;
}

.name {
  font-size: 2rem;
  font-weight: 700;
}

.date {
  font-size: 1.4rem;
}

.stars_div {
  display: flex;
  gap: 1rem;
}

.star {
  font-size: 3rem;
  color: #f7cb73;
}

.quote {
  font-size: 1.55rem;
  text-align: center;
}

.google_logo {
  width: 6rem;
}

.card:hover {
  transform: scale(0.97);
}
