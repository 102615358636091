.hero_wrapper {
  height: 50rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero_wrapper h1 {
  color: #fff;
  font-size: 7rem;
  text-transform: capitalize;
}

.hero_text_div {
  width: 100%;
  position: absolute;
  bottom: 0;
  transform: translate(0, 50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero_text_div p {
  color: #fff;
  font-size: 1.45rem;
  text-align: center;

  background-color: var(--accent-colour);
  border-radius: 1rem;
  max-width: 80rem;
  padding: 4rem 5rem;
}

@media screen and (max-width: 680px) {
  .hero_wrapper {
    height: 40rem;
  }

  .hero_wrapper h1 {
    font-size: 5rem;
  }

  .hero_text_div p {
    padding: 3rem 3rem;
  }
}
