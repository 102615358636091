.services_section {
  padding-top: 10rem;
}

.services_section h1 {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 5rem;
}

.services_div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  gap: 5rem;
}

.services_item {
  background-color: var(--secondary-colour);
  border-radius: 1rem;
  width: 100%;
  overflow: hidden;
  transition: 0.5s;
  cursor: pointer;
  text-decoration: none;
}

.services_item:hover {
  transform: scale(0.99);
  background-color: var(--secondary-dark-colour);
}

.services_item img {
  width: 100%;
  height: 25rem;
  object-fit: cover;
}

.services_item div {
  padding: 3rem;
}

.services_item div h2 {
  font-size: 2.1rem;
}

.services_item div p {
  font-size: 1.55rem;
  margin-top: 2rem;
}

@media screen and (max-width: 979px) {
  .services_div {
    gap: 3rem;
  }
}

@media screen and (max-width: 680px) {
  .services_div {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}
