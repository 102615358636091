.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem;
}

.item {
  background-color: var(--secondary-colour);
  border-radius: 1rem;
  overflow: hidden;
}

.colour {
  aspect-ratio: 1/1;
}

.name {
  text-transform: capitalize;
  padding: 1rem 0;
  text-align: center;
  font-size: 1.55rem;
  font-style: italic;
}

@media screen and (max-width: 680px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
}
