.title {
  font-size: 2.2rem !important;
  margin-bottom: 2rem;
}

.right_div {
  width: 50rem;
  background-color: var(--secondary-dark-colour);
  padding: 2rem;
  border-radius: 1rem;
}

.right_div form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.input_fields {
  background: transparent;
  border: 0.2rem solid #ced4da;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  font-family: 'Inter';
  outline: none;
  text-transform: capitalize;
  font-size: 1.4rem;
}

.input_fields:focus {
  border-color: var(--accent-colour);
  outline-color: var(--accent-colour);
}

.right_div textarea {
  text-transform: lowercase;
}

.submit {
  background-color: var(--accent-colour);
  color: #fff;
  font-weight: 600;
  border: none;
  border-radius: 0.5rem;
  font-size: 1.5rem;
  margin-top: 5rem;
  transition: 0.5s;
  cursor: pointer;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.submit:hover {
  transform: scale(0.97);
  background-color: var(--accent-hover-colour);
}

@media screen and (max-width: 979px) {
  .right_div {
    width: 40rem;
  }
}

@media screen and (max-width: 680px) {
  .right_div {
    width: 100%;
  }

  .form_container {
    order: -1;
  }
}
