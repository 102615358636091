.gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin-top: 5rem;
}

.gallery img {
  width: 100%;
  height: 20rem;
  border-radius: 1rem;
  object-fit: cover;
  transition: 0.5s;
}

.gallery img:hover {
  cursor: pointer;
  transform: scale(0.95);
}

.gallery img:nth-child(1) {
  grid-column-start: 1;
  grid-column-end: 3;
}

.gallery img:nth-child(5) {
  grid-column-start: 2;
  grid-column-end: 4;
}

.gallery img:nth-child(7) {
  grid-column-start: 1;
  grid-column-end: 3;
}

@media screen and (max-width: 680px) {
  .gallery {
    grid-template-columns: repeat(3, 1fr);
  }

  .gallery img:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .gallery img:nth-child(4) {
    grid-column-start: 2;
    grid-column-end: -1;
  }

  .gallery img:nth-child(5) {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .gallery img:nth-child(7) {
    grid-column-start: 1;
    grid-column-end: 2;
  }
}
