@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  letter-spacing: 0.2px;
}

html {
  font-size: 10px; /* 62.5% */
  scroll-behavior: smooth;
}

:root {
  --background-colour: #f8f9fa;
  --accent-colour: #fc3434;
  --accent-hover-colour: #c70000;
  --accent-hover-colour-light: #ffd0d0;
  --secondary-colour: #f1f3f5;
  --secondary-dark-colour: #e9ecef;
  --text-colour: #151515;
}

p,
h2 {
  color: var(--text-colour);
}

p,
li {
  font-size: 1.3rem;
}

body {
  font-family: 'Inter', sans-serif;
  background-color: var(--background-colour);
  font-weight: 400;
  line-height: 1.6;
  color: var(--text-colour);
}

.container {
  width: 80vw;
  margin: 0 auto;
}

.mini-container {
  max-width: 153rem;
  margin: 0 auto;
  padding: 0 5rem;
}

.mini-mini-container {
  max-width: 128rem;
  margin: 0 auto;
  padding: 0 5rem;
}

.CTA {
  background-color: var(--accent-colour);
  color: #fff;
  align-self: flex-start;
  text-decoration: none;
  padding: 2rem 4rem;
  font-size: 1.55rem;
  border-radius: 0.5rem;
  transition: 0.5s;
  cursor: pointer;
  letter-spacing: 0.05rem;
  outline: none;
  border: none;
}

.CTA:hover {
  background-color: var(--accent-hover-colour);
  transform: scale(0.97);
}

.button_div {
  margin-top: 5rem;
  width: 100%;
  display: flex;
  justify-content: center;
}

.button {
  background-color: var(--accent-colour);
  color: #fff;
  text-decoration: none;
  padding: 2rem 4rem;
  font-size: 1.55rem;
  border-radius: 0.5rem;
  transition: 0.5s;
  cursor: pointer;
  letter-spacing: 0.05rem;
  outline: none;
  border: none;
  text-transform: lowercase;
}

.button:hover {
  background-color: var(--accent-hover-colour);
  transform: scale(0.97);
}

.hidden {
  display: none !important;
}

.margin-top-mini {
  margin-top: 5rem !important;
}

.margin-top-small {
  margin-top: 10rem !important;
}

.margin-top-medium {
  margin-top: 15rem !important;
}

.margin-top-large {
  margin-top: 20rem !important;
}

/************** SWIPER START **************/

.swiper_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  margin-top: 5rem;
}

.icons {
  color: var(--accent-colour);
  font-size: 3.5rem;
  transition: 0.5s;
  padding: 0.5rem;
  border-radius: 100%;
}

.icons:hover {
  cursor: pointer;
  color: var(--secondary-colour);
  background-color: var(--accent-colour);
}

.slider-pagination {
  width: auto !important;
}

.swiper-pagination-bullet-active {
  background-color: var(--accent-colour) !important;
}

/************** SWIPER END **************/

@media screen and (max-width: 1200px) {
  html {
    font-size: 9px; /* 62.5% */
  }
}

/************** MEDIA QUERT START **************/

/* screen sizes: 
- 1200px
- 979px
- 768px
- 680 */

@media screen and (max-width: 979px) {
  html {
    font-size: 8.5px; /* 62.5% */
  }

  .mini-container {
    padding: 0 3rem;
  }

  .mini-mini-container {
    padding: 0 3rem;
  }
}

@media screen and (max-width: 768px) {
  html {
    font-size: 8px; /* 62.5% */
  }

  .mini-mini-container {
    padding: 0 3rem;
  }
}

@media screen and (max-width: 680px) {
  .container {
    width: 89vw;
  }

  .mini-container {
    padding: 0 2rem;
  }

  .mini-mini-container {
    padding: 0 1.5rem;
  }
}

/************** MEDIA QUERT END **************/
