.serve_area_wrapper {
  margin-top: 20rem;
  padding: 10rem 0;
  background-color: var(--accent-colour);
}

.serve_area_div {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  align-items: center;
}

.serve_area_div h1 {
  font-size: 3rem;
  color: #fff;
}

.serve_area_div p {
  text-align: center;
  color: #fff;
  font-size: 1.55rem;
}

.icons_div {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.icon_item {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.icon {
  font-size: 4rem;
  color: #fff;
}

.icon_item p {
  font-size: 1.55rem;
}

.slogan {
  font-style: italic;
  font-size: 1.5rem !important;
}

@media screen and (max-width: 768px) {
  .icons_div {
    justify-content: space-between;
    padding: 0 2rem;
  }
}

@media screen and (max-width: 680px) {
  .icons_div {
    justify-content: space-between;
    padding: 0;
  }

  .icon_item {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .icon {
    font-size: 3rem;
    color: #fff;
  }
}
