.grid {
  margin-top: 5rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem;
}

.item {
  background-color: var(--secondary-colour);
  text-align: center;
  border-radius: 1rem;
  overflow: hidden;
  transition: 0.5s;
  cursor: pointer;
}

.item:hover {
  transform: scale(0.95);
}

.item img {
  width: 100%;
  height: 20rem;
  object-fit: cover;
}

.item p {
  padding: 1rem 0;
  font-style: italic;
  font-size: 1.4rem;
}

@media screen and (max-width: 680px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
}
