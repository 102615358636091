.faq_question_div {
  background-color: var(--secondary-colour);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 3rem;
  border-radius: 0.5rem;
  transition: 0.5s;
  outline: none;
}

.faq_question_div:hover {
  cursor: pointer;
  transform: scale(0.99);
  background-color: var(--secondary-dark-colour);
}

.faq_question_div p {
  font-size: 1.55rem;
}

.faq_question_div_arrow {
  font-size: 3rem;
  color: var(--accent-colour);
  margin-left: 2rem;
}

.faq_answer_div {
  padding: 2rem 3rem 4rem 3rem;
  display: none;
}

.faq_answer_div p {
  font-size: 1.55rem;
}

.faq_question_div_clicked {
  background-color: var(--secondary-colour);
}

.faq_answer_div_show {
  display: block;
}
